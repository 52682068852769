@import '../../../../scss/theme-bootstrap';

.breadcrumbs {
  display: flex;
  z-index: 2;
  color: $color-dark-gray;
  @include breadcrumb-font;
  &__level {
    display: inline-block;
    a {
      text-decoration: none;
    }
    .icon--caret--right {
      @include ltr-rule(margin, -.2em .35em 0 .25em);
      height: .75em;
      width: .75em;
    }
    &:first-child:before {
      display: none;
    }
  }
}
